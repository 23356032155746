<template>
    <nav-bar navName="حلقات مؤمن بجامع الصانع" dir="rtl"></nav-bar>
    
    <div class="container mt-4">
        <form class="d-flex mb-4">
            <input v-model="search" class="form-control me-sm-2 text-primary" type="text" placeholder="بحث">
            <button @click.prevent="filter" class="btn btn-secondary my-2 my-sm-0 " type="submit">بحث</button>
        </form>
        <router-link :to="{name: 'AllStudent'}" >نقاط الطلاب</router-link>

        <table class="table table-hover" dir="rtl">
            
        <thead>
            <tr>
            <th scope="col">#</th>
            <th scope="col">الإسم</th>
            <th scope="col">رقم الهوية</th>
            <th scope="col"></th>
            <th scope="col">النقاط الحالية</th>
            <th scope="col"></th>
            <th scope="col">تحديث</th>
            <th scope="col">تعديل</th>
            <th scope="col">حذف</th>
            </tr>
        </thead>
        <tbody>
            
            <tr v-for="(student, index) in students" class="">
                <!-- {{student.id + 'test'}} -->
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ student.name }}</td>
                <td>{{ student.card_id }}</td>
                <td>
                    <!-- <button type="button" @click.prevent="student.point = (student.point + 1)" class="btn btn-dark w-50">+</button> -->
                    <button type="button" @click.prevent="student.point = parseInt(student.point) + parseInt(1)" class="btn btn-dark w-50">+</button>
                    <button type="button" @click.prevent="student.point = parseInt(student.point) - parseInt(1)" class="btn btn-dark w-50">-</button>
                </td>
                <td>
                </td>
                <td>
                    <input type="text" class="form-control col-4" id="point" :value="student.point" disabled style="color:black">
                </td>
                <td>
                    <button style="background: linear-gradient(to right, #373b44, rgb(110,74,14)); color:white" @click.prevent="updateStudent(student.id,student.name,student.card_id,student.point)">تحديث</button>
                </td>
                <td>
                    <router-link :to="{name: 'EditStudent', params: {id: student.id}}" class="btn btn-dark btn-sm">تعديل</router-link>
                </td>
                <td>
                    <button class="btn btn-danger btn-sm" @click.prevent="deleteStudent(student.id)">حذف</button>
                </td>
            </tr>
        </tbody>
        </table>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios'

export default{
    name: 'IndexStudent',
    components: {
    NavBar,
  },
  data(){
    return {
        students: null,
        name: '',
        card_id: '',
        point: '',
        search: '',
    }
  },
  created(){
    this.getStudents();
  },
  methods:{
    async getStudents(){
        const response = await axios.get('admin/student');
        this.students = response.data.data;
    },
    async updateStudent(id,name,card_id,point){
        const response = await axios.put(`admin/student/${id}`, {
            name: name,
            card_id: card_id,
            point: point,
        })
        // console.log(response)
    },
    deleteStudent(id){
        this.$swal.fire({
                toast: true,
                title: 'هل تريد الحذف',
                icon: 'question',
                iconHtml: '؟',
                confirmButtonText: 'نعم',
                cancelButtonText: 'لا',
                showCancelButton: true,
                showCloseButton: true
                }).then(result=>{
                if(result.isConfirmed){
                    const response = axios.delete(`admin/student/${id}`)
                    this.getStudents()
                }
                })
    },
    async filter(){
        const response = await axios.get('filter?name=' + this.search)
        this.students = response.data.data
    }
  },

}
</script>

