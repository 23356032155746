import { createRouter, createWebHistory } from 'vue-router'
import IndexStudent from '@/components/admin/student/IndexStudent.vue'
import CreateStudent from '@/components/admin/student/CreateStudent.vue'
import EditStudent from '@/components/admin/student/EditStudent.vue'
import AllStudent from '@/components/front/student/AllStudent.vue'
import Login from '@/components/admin/student/Login.vue'

const routes = [
    {
        name: 'Login',
        path: '/admin',
        component: Login,
    },
    {
        name: 'IndexStudent',
        path: '/admin/student',
        component: IndexStudent,
        meta: { requiresAuth: true },  // This route requires authentication
    },
    {
        name: 'CreateStudent',
        path: '/admin/student/add',
        component: CreateStudent,
        meta: { requiresAuth: true },  // This route requires authentication
    },
    {
        name: 'EditStudent', 
        path: '/admin/student/:id', 
        component: EditStudent,
        meta: { requiresAuth: true },  // This route requires authentication
    },
    {
        name: 'AllStudent',
        path: '/',
        component: AllStudent,
    },
];

const router = createRouter({
    history: new createWebHistory(),
    routes
});

// Navigation Guard
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    const currentTime = new Date().getTime();

    if (to.matched.some(record => record.meta.requiresAuth) && !token) {
      next({ name: 'Login' });
    } else {
      next();
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (token && tokenExpiration && currentTime < tokenExpiration) {
          // Token is valid, proceed to the route
          next();
        } else {
          // Token is invalid or expired, redirect to login
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiration');
          next({ name: 'Login' });
        }
      } else {
        next();
    }
});

export default router;
