<template>
    <div class="login-page">
      <h2>تسجيل دخول</h2>
      <form @submit.prevent="login">
        <div class="form-group" style="text-align: center">
          <label for="email">الإيميل:</label>
          <input type="email" v-model="email" required />
        </div>
        <div class="form-group" style="text-align: center">
          <label for="password">كلمة المرور:</label>
          <input type="password" v-model="password" required />
        </div>
        <button type="submit" class="login-button">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useRouter } from 'vue-router';
  import { ref } from 'vue';
  
  export default {
    name: 'LoginPage',
    setup() {
      const email = ref('');
      const password = ref('');
      const router = useRouter();
  
      const login = async () => {
        try {
          const response = await axios.post('login', {
            email: email.value,
            password: password.value
          });
  
          // Store the token and its expiration time
          const expirationTime = new Date().getTime() + (60 * 60 * 1000); // 60 minutes
          localStorage.setItem('token', response.data.data.token);
          localStorage.setItem('tokenExpiration', expirationTime.toString());
  
          // Navigate to the IndexStudent page
          router.push({ name: 'IndexStudent' });
        } catch (error) {
          console.error('Login failed:', error);
        }
      };
  
      return {
        email,
        password,
        login
      };
    }
  };
  </script>
  
  <style scoped>
  .login-page {
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    background-color: rgb(110, 74, 14);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  h2 {
    text-align: center;
    color: #ffffff; /* لون بني داكن */
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #ffffff; /* لون بني داكن */
  }
  
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    background-color: #fffaf0; /* لون خلفية فاتح */
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  input[type="email"]:focus,
  input[type="password"]:focus {
    border-color: #8b5e3c; /* لون بني داكن */
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #2e1d0e; /* لون بني داكن */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-button:hover {
    background-color: #200d02; /* لون بني أغمق قليلاً */
  }
  
  .login-button:active {
    background-color: #6e4530; /* لون بني أغمق عند الضغط */
  }
  </style>
  