<template>
    <nav-bar navName="حلقات مؤمن بجامع الصانع" dir="rtl"></nav-bar>
    <div class="container mt-4" >
        <form @submit.prevent="storeStudent" dir="rtl">
            <div class="mb-3" >
                <label for="name" class="form-label">الإسم</label>
                <input type="text" v-model="form.name" class="form-control" id="name" aria-describedby="name">
                <div id="emailHelp" class="form-text"></div>
               
            </div>
            <div class="mb-3">
                <label for="card_id" class="form-label">الهوية</label>
                <input type="number" v-model="form.card_id" class="form-control" id="card_id">
                
            </div>
            <div class="mb-3">
                <label for="point" class="form-label">النقاط</label>
                <input type="number" v-model="form.point" class="form-control" id="point">
                
            </div>
            
            <button type="submit" style="background: linear-gradient(to right, #373b44, rgb(110,74,14)); color:white">إضافة</button>
        </form>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios'

export default {
    name: 'CreateStudent',
    components: {
    NavBar,
  },
  data(){
    return {
        form: {
            name: '',
            card_id: null,
            point: null,
        }
    }
  },
  created(){
    
  },
  methods:{
    async storeStudent(){
        const response = axios.post('admin/student', {
            name: this.form.name,
            card_id: this.form.card_id,
            point: this.form.point
        })
        this.$swal('تم إضافة الطالب بنجاح');
        this.reset();
        // console.log(response)
    },
    reset(){
        this.form.name = '';
        this.form.card_id = '';
        this.form.point = '';
    }
  }
}
</script>