<template>
    <div style="background-color: rgb(110,74,14); color:white; border-radius: 0px; background-image: url(@/assets/loSanea.png);">
        <h3 class="text-center pt-2 pb-2"> 
            <img src="@/assets/loSanea.png" style="width: 8%; position: relative; margin-top:25px ;float: left" alt="">
            الطلاب المتميزين بحلقات مؤمن</h3>
    </div>
    <div class="bg-image">
        <div class="d-flex justify-content-center">
        <div class="col-md-10 ">
            <div class="row ">
                <div v-for="student in students" class="col-xl-4 col-lg-6">
                    <div class="card l-bg-blue-dark">
                        <div class="card-statistic-3 p-3">
                            <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                            <div class="mb-4">
                                <h5 class="card-title mb-0">{{ student.name }}</h5>
                            </div>
                            <div class="row align-items-center mb-2 d-flex">
                                <div class="col-8">
                                    <h2 class="d-flex align-items-center mb-0">
                                        {{ student.point }}
                                    </h2>
                                </div>
                                <div class="col-4 text-right">
                                    <span>النقاط <i class="fa fa-arrow-up"></i></span>
                                </div>
                            </div>
                            <div class="progress mt-1 " data-height="8" style="height: 8px;">
                                <div class="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'AllStudent',
        data(){
            return {
                students: null,
            }
        },
        created(){
            this.getStudents()
        },
        methods:{
            async getStudents(){
                const response = await axios.get('student');
                this.students = response.data.data;
                console.log(response)
            }
        }
    }

</script>

<style scoped>
    .card {
        background-color: #fff;
        border-radius: 30px;
        border: none;
        position: relative;
        margin-bottom: 10px;
        box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
    }
    .l-bg-cherry {
        background: linear-gradient(to right, #493240, #f09) !important;
        color: #fff;
    }

    .l-bg-blue-dark {
        background: linear-gradient(to right, #373b44, rgb(110,74,14)) !important;
        color: #fff;
    }

    .l-bg-green-dark {
        background: linear-gradient(to right, #0a504a, #38ef7d) !important;
        color: #fff;
    }

    

    .card .card-statistic-3 .card-icon-large .fas, .card .card-statistic-3 .card-icon-large .far, .card .card-statistic-3 .card-icon-large .fab, .card .card-statistic-3 .card-icon-large .fal {
        font-size: 110px;
    }

    .card .card-statistic-3 .card-icon {
        text-align: center;
        line-height: 50px;
        margin-left: 15px;
        color: #000;
        position: absolute;
        right: -5px;
        top: 30px;
        opacity: 0.1;
    }

    .l-bg-cyan {
        background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
        color: #fff;
    }

    .l-bg-green {
        background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
        color: #fff;
    }

    .l-bg-orange {
        background: linear-gradient(to right, #f9900e, #ffba56) !important;
        color: #fff;
    }

    .l-bg-cyan {
        background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
        color: #fff;
    }

</style>