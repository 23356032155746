<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary" style="background: linear-gradient(to right, #373b44, rgb(110,74,14))">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">{{navName}}</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarColor01">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'IndexStudent' }">الطلاب
                <span class="visually-hidden">(current)</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'CreateStudent' }">إضافة طالب</router-link>
            </li>
          </ul>
          <!-- <form class="d-flex">
            <input class="form-control me-sm-2 text-primary" type="text" placeholder="بحث">
            <button class="btn btn-secondary my-2 my-sm-0 " type="submit">بحث</button>
          </form> -->
        </div>
      </div>
    </nav>
</template>

<script>
export default{
    name: 'NavBar',
    props: ['navName'],
}
</script>