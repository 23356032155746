<template>
    <nav-bar navName="حلقات مؤمن بجامع الصانع" dir="rtl"></nav-bar>
    
    <div class="container mt-4" >
        <form @submit.prevent="updateStudent" dir="rtl">
            <div class="mb-3" >
                <label for="name" class="form-label text-center">الإسم</label>
                <input type="text" v-model="student.name" class="form-control" aria-describedby="name">
                <div id="emailHelp" class="form-text"></div>
               
            </div>
            <div class="mb-3">
                <label for="card_id" class="form-label">الهوية</label>
                <input type="number"  class="form-control" v-model="student.card_id">
                
            </div>
            <div class="mb-3">
                <label for="point" class="form-label">النقاط</label>
                <input type="number" class="form-control" v-model="student.point">
                
            </div>
            
            <button type="submit" style="background: linear-gradient(to right, #373b44, rgb(110,74,14)); color:white">تعديل</button>
        </form>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios'

export default {
    name: 'EditStudent',
    components: {
    NavBar,
  },
  data(){
    return {
        student: null,
    }
  },
  created(){
    this.getStudent()
  },
  methods:{
    async getStudent(){
        const response = await axios.get(`admin/student/${this.$route.params.id}`)
        this.student = response.data.data
    },
    async updateStudent(){
        const response = await axios.put(`admin/student/${this.$route.params.id}`, {
            name: this.student.name,
            card_id: this.student.card_id,
            point: this.student.point,
        })
        console.log(response)
    }
  }
}
</script>