<template>
  
  <router-view></router-view>
  
</template>

<script>


export default {
  name: 'App',

  created(){
        // var currentUrl = window.location.pathname;
        // console.log(currentUrl);
        }  
}
</script>

<style>

</style>
